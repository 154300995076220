
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <form @submit.prevent="updateInvoices" autocomplete="off">
                <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="stores" v-model="invoices.store_id" dense  filled outlined item-text="store_id"
                                    item-value="store_id" :return-object="false" :label="$store.getters.language.data.invoices.store_id">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="invoices.invoice_date" type="date" :label="$store.getters.language.data.invoices.invoice_date" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="users" v-model="invoices.user_id" dense  filled outlined item-text="user_id"
                                    item-value="user_id" :return-object="false" :label="$store.getters.language.data.invoices.user_id">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="customers" v-model="invoices.customer_id" dense  filled outlined item-text="customer_id"
                                    item-value="customer_id" :return-object="false" :label="$store.getters.language.data.invoices.customer_id">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="invoices.invoice_note" type="textarea" :label="$store.getters.language.data.invoices.invoice_note" dense
                            class="mx-1"  filled outlined  >
                        </v-text-field>
                    </v-flex>
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="['naqd','qarz','qist']" v-model="invoices.invoice_status" dense  filled outlined item-text="invoice_status"
                                    item-value="invoice_status" :return-object="false" :label="$store.getters.language.data.invoices.invoice_status">
                                </v-select>
                        </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="invoices.invoice_qist_count" type="number" :label="$store.getters.language.data.invoices.invoice_qist_count" dense
                            class="mx-1"  filled outlined  >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="accounts" v-model="invoices.account_id" dense  filled outlined item-text="account_id"
                                    item-value="account_id" :return-object="false" :label="$store.getters.language.data.invoices.account_id">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="customer_addresses" v-model="invoices.customer_address_id" dense  filled outlined item-text="customer_address_id"
                                    item-value="customer_address_id" :return-object="false" :label="$store.getters.language.data.invoices.customer_address_id">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="success"     type="submit">{{$store.getters.language.data.invoices.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/invoices.request.js'
    export default {
        data() {
            return {
                invoices: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            
                    undefined(){
                        return this.$store.getters.undefined_list
                    },
                    
                    undefined(){
                        return this.$store.getters.undefined_list
                    },
                    
                    undefined(){
                        return this.$store.getters.undefined_list
                    },
                    
                    undefined(){
                        return this.$store.getters.undefined_list
                    },
                    
                    undefined(){
                        return this.$store.getters.undefined_list
                    },
                    
        },
        mounted(){
            this.id = this.$route.params.id
            this.getOneInvoices()
        },
        methods: {
            getOneInvoices() {
                this.loading = true
                requests.getOneInvoices(this.id).then(r =>{
                    if (r.status == 200) {
                        this.invoices = r.data.row
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateInvoices() {
                this.loading = true
                requests.updateInvoices(this.id,this.invoices).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.update_success,
                            color: 'success'
                        }
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    